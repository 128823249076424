import { appID, authorizeUrl, redirectUrl } from '@/config'

/**
 * @description 组装请求地址
 * @returns {string}
 */
export function makeUrl(state) {
  return (
    authorizeUrl +
    '?appid=' +
    appID +
    '&redirect_uri=' +
    encodeURI(redirectUrl) +
    '&response_type=code&scope=snsapi_base&state=' +
    state +
    '#wechat_redirect'
  )
}

/**
 * @description 获取url中特定的值
 * @param name
 * @returns {string}
 */
export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}
