<template>
  <div class="body-bg">
    <div class="login">
      <img src="../assets/home_images/loading.gif" alt="" />
    </div>
  </div>
</template>
<script>
  import store from '@/store'
  import { mapActions } from 'vuex'
  import { jsApiList } from '@/config'
  import { Toast } from 'vant'
  import { getOpenID, getWXConfigInfo } from '@/api/wechat/wechat'
  import { getUserInfo } from '@/api/wechat/account/account'
  import { makeUrl, getUrlKey } from '@/util/wechat'
  import { getDayInfo } from '@/api/basic/day/day'

  export default {
    name: 'Home',
    beforeRouteEnter(to, from, next) {
      console.log(from)
      if (
        from.path === '/index' ||
        from.path === '/appointment' ||
        from.path === '/message'
      ) {
        wx.closeWindow()
      } else {
        return next()
      }
    },
    data() {
      return {
        formData: {
          ident: 'OfficAccount',
          level: '1',
        },
        jsApiList,
        state: '',
      }
    },
    async mounted() {
      let code = getUrlKey('code')
      let state = getUrlKey('state')
      if (code !== '' && code != null && code != undefined) {
        await this.getOpenID(code, state)
        await this.getWxConfig()
      } else {
        window.location.href = makeUrl(state)
      }
    },
    methods: {
      ...mapActions({
        login: 'user/login',
      }),
      //获取OpenID
      async getOpenID(code, state) {
        const { data, success } = await getOpenID({ code: code })
        if (success) {
          await store.dispatch('user/setOpenId', data)
          await this.login(this.formData)
          await this.getUserInfo(data)
          await this.pageInit()
          switch (state) {
            case 'ghyy': //挂号预约
              await this.$router.push({
                path: '/message',
                query: { sign: state },
              })
              break
            case 'yyjl': //预约记录
              await this.$router.push('/appointment')
              break
            case 'gzrgl': //就诊人管理
              await this.$router.push({
                path: '/message',
                query: { sign: state },
              })
              break
            //门诊排班
            case 'mzpb':
              await this.$router.push('/outpatientSchedule')
              break
            //排队动态
            case 'pddt':
              await this.$router.push('/queue')
              break
            //住院清单
            case 'zydq':
              await this.$router.push('/hospitalFee')
              break
            //住院预交金
            case 'zyyjj':
              await this.$router.push('/preFee')
              break
            //病历复印
            case 'blfy':
              await this.$router.push({
                path: '/message',
                query: { sign: state },
              })
              break
            //病历复印列表
            case 'blfylb':
              await this.$router.push('/caseCopy')
              break
            //检验检查
            case 'jyjc':
              await this.$router.push('/inspect')
              break
            //满意度
            case 'mzmyd':
              await this.$router.push({
                path: '/survey',
                query: { type: '1' },
              })
              break
            case 'zymyd':
              await this.$router.push({
                path: '/survey',
                query: { type: '2' },
              })
              break
            //停诊信息
            case 'tzxx':
              await this.$router.push('/doctorClose')
              break
            //缴费记录
            case 'jfjl':
              await this.$router.push('/pay')
              break
            //就诊记录
            case 'jzjl':
              await this.$router.push('/seeDoctor')
              break
            //药品查询
            case 'ypcx':
              await this.$router.push('/drug')
              break
            //物价查询
            case 'wjcx':
              await this.$router.push('/project')
              break
            //眼健康
            case 'yjk':
              await this.$router.push('/yjk')
              break
            //床位预约
            case 'cwyy':
              await this.$router.push({
                path: '/message',
                query: { sign: state },
              })
              break
            default:
              await this.$router.push('/index')
              break
          }
        } else {
          wx.closeWindow()
        }
      },
      //获取用户信息
      async getUserInfo(openId) {
        const { data } = await getUserInfo({ OpenId: openId })
        await store.dispatch('user/getUserInfo', JSON.stringify(data))
      },

      async pageInit() {
        const { data } = await getDayInfo({ ID: 1 })
        sessionStorage.setItem('day', JSON.stringify(data))
      },

      async getWxConfig() {
        let url = location.href
        const { data } = await getWXConfigInfo({ Url: url })
        console.log(data)
        wx.config({
          debug: false,
          appId: data.appid,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.signature,
          jsApiList: jsApiList,
        })
      },
    },
  }
</script>
<style scoped>
  .body {
    margin-top: 50px;
  }

  .login img {
    width: 150%;
    margin-left: -25%;
  }

  .body-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: 100%;
    background-color: #fff;
  }
</style>
